export default {
  methods: {
    getValidationErrors(error) {
      if (error.response && error.response.status === 422) {
        return error.response.data.errors
      }

      return []
    },

    getErrorMessage(error, defaultMessage = null) {
      console.log('error', error)
      const message =
        error?.response?.data?.message || error?.response?.data?.error
      return message || defaultMessage || 'Something went wrong...'
    },
  },
}
